<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="mt-2 ml-2">
      <b-row>
        <b-col cols="12">
          <h2>Pessoas sem PG</h2>
        </b-col>
      </b-row>
    </div>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col cols="12" md="4" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
            :clearable="false" class="per-page-selector d-inline-block ml-50 mr-1" />
        </b-col>

        <!-- Search -->
        <b-col cols="12" md="8">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Filtrar..." />
            <vue-json-to-csv :json-data="filterPessoas(pessoasSemPg)" :labels="{
              name: { title: 'Nome' },
              email: { title: 'E-mail' },
              phone: { title: 'Telefone' },
            }" csv-title="PessoasSemPg" separator=";">
              <b-button variant="primary">
                Download
              </b-button>
            </vue-json-to-csv>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table :items="filterPessoas(pessoasSemPg)" responsive :fields="tableColumns" primary-key="id"
      :sort-by.sync="sortBy" show-empty empty-text="No matching records found" :sort-desc.sync="isSortDirDesc"
      class="position-relative" stacked="sm" :per-page="perPage" :current-page="currentPage">
      <template #cell(phone)="data">
        {{ mtel(data.value) }}
      </template>
      <template #head(invoiceStatus)>
        <feather-icon icon="TrendingUpIcon" class="mx-auto" />
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col cols="12" sm="6" class="
            d-flex
            align-items-center
            justify-content-center justify-content-sm-start
          ">
          <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} de
            {{ dataMeta.of }} pessoas sem PG</span>
        </b-col>
        <!-- Pagination -->
        <b-col cols="12" sm="6" class="
            d-flex
            align-items-center
            justify-content-center justify-content-sm-end
          ">
          <b-pagination v-model="currentPage" :total-rows="totalInvoices" :per-page="perPage" first-number last-number
            class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { ref, onUnmounted, computed } from "@vue/composition-api";
import store from "@/store";
import VueJsonToCsv from 'vue-json-to-csv';

import invoiceStoreModule from "./pessoasSemPgStoreModule";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    VueJsonToCsv,
    vSelect,
  },
  setup() {
    try {
      const INVOICE_APP_STORE_MODULE_NAME = "apppessoasSemPg";

      // Register module
      if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
        store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule);

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
          store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
      });


      const fetchPessoasSemPg = () => {
        store.dispatch("apppessoasSemPg/fetchPessoasSemPg");
      };

      fetchPessoasSemPg();

      const pessoasSemPg = computed(() => store.state.apppessoasSemPg.pessoasSemPg);
      const totalInvoices = computed(
        () => store.state.apppessoasSemPg.pessoasSemPg.length
      );

      const dataMeta = computed(() => {
        return {
          from: perPage.value * currentPage.value + 1 - perPage.value,
          to:
            perPage.value * currentPage.value > totalInvoices.value
              ? totalInvoices.value
              : perPage.value * currentPage.value,
          of: totalInvoices.value,
        };
      });

      const tableColumns = [
        { key: "id", label: "#", sortable: true },
        { key: "name", label: "Nome", sortable: true },
        { key: "email", label: "Email", sortable: true },
        { key: "phone", label: "Telefone", sortable: true },
      ];
      const perPage = ref(10);
      const currentPage = ref(1);
      const perPageOptions = [10, 25, 50, 100];
      const searchQuery = ref("");
      const sortBy = ref("name");
      const isSortDirDesc = ref(false);

      function mtel(tel) {
        tel = tel.replace(/\D/g, "");
        tel = tel.replace(/^(\d)/, "+$1");
        tel = tel.replace(/(.{3})(\d)/, "$1 ($2");
        tel = tel.replace(/(.{7})(\d)/, "$1) $2");
        if (tel.length == 12) {
          tel = tel.replace(/(.{1})$/, "-$1");
        } else if (tel.length == 13) {
          tel = tel.replace(/(.{2})$/, "-$1");
        } else if (tel.length == 14) {
          tel = tel.replace(/(.{3})$/, "-$1");
        } else if (tel.length == 15) {
          tel = tel.replace(/(.{4})$/, "-$1");
        } else if (tel.length > 15) {
          tel = tel.replace(/(.{4})$/, "-$1");
        }
        return tel;
      }


      function filterPessoas(pessoas) {

        pessoas = pessoas.filter(
          (word) =>
            word.name
              .toLowerCase()
              .includes(searchQuery.value.toLowerCase()) ||
            word.email
              .toLowerCase()
              .includes(searchQuery.value.toLowerCase())
        );

        pessoas = pessoas.map(item => {
          item.phone = mtel(item.phone);
          return item;
        });

        totalInvoices.value = pessoas.length ?? 0;

        return pessoas;
      }

      return {
        tableColumns,
        perPage,
        currentPage,
        totalInvoices,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,

        filterPessoas,
        avatarText,
        fetchPessoasSemPg,
        pessoasSemPg,
        mtel,
      };
    } catch (ex) {
      console.log(ex);
    }
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
