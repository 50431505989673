import axios from '@axios'

export default {
  namespaced: true,
  state: {
    pessoasSemPg: []
  },
  getters: {
    pessoasSemPg: state => {
      return state.pessoasSemPg
    }
  },
  mutations: {
    pessoasSemPg(ctx, pessoasSemPg) {
      ctx.pessoasSemPg = pessoasSemPg
    }
  },
  actions: {
    fetchPessoasSemPg(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('https://reports.renovaremcristo.org.br/renovar-reports/api/executor', {
          "key": "PESSOAS_SEM_PGS",
          "params": {}
        })
        .then(function(response) {
          ctx.commit('pessoasSemPg', response.data.data)
          return resolve(response)
        })
        .catch(error => reject(error))
      })
    }
  },
}
